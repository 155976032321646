<template>
  <Main>
    <template v-slot:content>
      <div class="order container">
        <div class="order__container">
          <div class="order__logo">
            <img src="/images/logo.png" alt="" />
          </div>
          <div class="order__title">
            {{ $t("message.Thank_you") }}
          </div>
          <div class="order__text">{{ $t("message.Thank_you_second") }}</div>
        </div>
      </div>
    </template>
    <template v-slot:footer></template>
  </Main>
</template>

<script>
import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";

export default {
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.getOrder();
  },
  components: {
    Main,
  },
  methods: {
    getOrder() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getOrderItem(url)
          .then((res) => {
            this.order = res;
            if (
              this.order.items &&
              this.order.items[0] &&
              this.order.items[0].project &&
              this.order.items[0].project.service_type &&
              this.order.items[0].project.service_type.value == "trial"
            ) {
              this.is_trial = true;
            }
          })
          .catch((error) => {
            console.error("Помилка при отриманні замовлення:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
</style>
<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;
  min-height: 80vh;
  align-items: center;

  .desc {
    display: block;
  }

  .mob {
    display: none;
  }

  &__container {
    margin-top: 30px;
    min-width: 900px;
    max-width: 1000px;
    padding: 30px;
    border-radius: 15px;
    border: 1px #d7d9dd;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    flex-direction: column;
    position: relative;
  }

  &__title {
    font-family: "Mirador";
    font-size: 30px;
    text-align: center;
    font-weight: 350;
    margin: 30px 0;
  }

  &__text {
    font-optical-sizing: auto;
    font-style: normal;
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    width: 75%;
  }

  &__logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;

    img {
      margin-right: 10px;
      height: 40px;
    }
  }

  &__payment-button {
    margin-top: 30px;
    padding: 12px 30px;
    background-color: #191919;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #000;
    }
  }

  &__payment-processing {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__payment-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #191919;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }

  &__payment-text {
    font-size: 16px;
    color: #666;
  }

  &__payment-status {
    margin-top: 20px;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    max-width: 80%;

    &--info {
      background-color: #e7f3fe;
      color: #2196f3;
    }

    &--success {
      background-color: #d4edda;
      color: #155724;
    }

    &--error {
      background-color: #f8d7da;
      color: #721c24;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1350px) {
    &__container {
      min-width: 750px;
      max-width: 750px;
    }
  }

  @media (max-width: 991px) {
    &__container {
      min-width: 90%;
      max-width: 90%;
      padding: 20px;
    }
  }

  @media (max-width: 568px) {
    &__container {
      min-width: 100%;
      max-width: 100%;
    }

    .desc {
      display: none;
    }

    .mob {
      display: block;
    }

    &__title {
      font-size: 20px;
    }

    &__text {
      font-size: 16px;
      width: 90%;
    }

    &__payment-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
}
</style>
